import { useCallback, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import { fetchTeams } from 'team/controllers/team';
import { getEmployeesList } from 'pto/controllers/pto';
import { fetchEmployees } from 'employee/controllers/employee';

import { deepEqual } from 'pages/addOrEditPtoPolicy/helper/utils';

import { PTO_POLICY_EVENTS } from 'pto/constants';
import { DEPARTMENT_EVENTS, TEAM_EVENTS, COMPANY_EVENTS, EMPLOYEE_EVENTS } from 'pages/shiftManagement/constants';
import { TOAST_MESSAGES } from 'pto/strings';

const useEmployeeData = (eventEmitter, setLoading, policyId, setLoader, setEmployeeLoader) => {
  const cookies = new Cookies();

  const [companies, setCompanies] = useState([]);
  const [defaultEmployees, setDefaultEmployees] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [pageEmployeeSelection, setPageEmployeeSelection] = useState();
  const [departmentsOptions, setDepartmentOptions] = useState([]);
  const [fetchState, setFetchState] = useState(null);
  const [teamsOptions, setTeamsOptions] = useState();
  const [employees, setEmployees] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(
    cookies.get('pageSizeEmployeeSelectionTab') !== 'undefined' ? cookies.get('pageSizeEmployeeSelectionTab') : 5,
  );

  const onTeamSelect = useCallback(
    data => {
      const teamIds = data.teams && data.teams.length ? data.teams.map(i => i.id) : [];
      const departmentIds =
        data.selectedDepartments && data.selectedDepartments.length
          ? data.selectedDepartments.map(item => item.id)
          : [];
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
        team_ids: JSON.stringify(teamIds),
        department_ids: JSON.stringify(departmentIds),
      });
    },
    [companies, eventEmitter],
  );

  const updateInputBalance = useCallback((value, rowIndex) => {
    const parsedValue = parseFloat(parseFloat(value).toFixed(2));
    setEmployeesList(oldData =>
      oldData.map((oldValue, index) => {
        if (index === rowIndex) {
          return {
            ...oldValue,
            balance: parsedValue,
          };
        } else {
          return oldValue;
        }
      }),
    );
  }, []);

  const onDepartmentSelect = useCallback(
    data => {
      const deptIds = data.departments && data.departments.length ? data.departments.map(i => i.id) : [];
      const teamIds = data.selectedTeams && data.selectedTeams.length ? data.selectedTeams.map(i => i.id) : [];
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      fetchTeams(eventEmitter, {
        paginate: false,
        company_id: companiesId,
        is_active: true,
        department_ids: JSON.stringify(deptIds),
      });

      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
        department_ids: JSON.stringify(deptIds),
        team_ids: JSON.stringify(teamIds),
      });
    },
    [companies, eventEmitter],
  );

  const pageChange = useCallback(
    item => {
      cookies.set('employeeSelectionTab', +item + 1, { path: '/' });
      item < 0 ? setCurrentPage(item + 1) : setCurrentPage(item);
    },
    [cookies],
  );

  const pageSizeChange = useCallback(
    (pageSize, pageIndex) => {
      cookies.set('pageSizeEmployeeSelectionTab', pageSize, { path: '/' });
      cookies.set('employeeSelectionTab', pageIndex, { path: '/' });
      setCurrentPageSize(pageSize);
      setCurrentPage(pageIndex);
    },
    [cookies],
  );

  const getPolicyEmployeesList = useCallback(
    fetchData => {
      setLoading(true);
      getEmployeesList(eventEmitter, {
        is_active: true,
        policyId,
        page_size: fetchData?.pageSize || currentPageSize,
        page: fetchData?.page + 1 || currentPage + 1,
      });
    },
    [setLoading, eventEmitter, policyId, currentPageSize, currentPage],
  );

  const onFetchData = useCallback(
    ({ page: nextPage, pageSize, sorted }) => {
      const nextFetch = { page: nextPage, pageSize, sorted };
      const prevFetch = fetchState;
      if (!deepEqual(nextFetch, prevFetch)) {
        setFetchState(nextFetch);
        getPolicyEmployeesList(nextFetch);
      }
    },
    [fetchState, getPolicyEmployeesList],
  );

  useEffect(
    function init() {
      const observable = eventEmitter.getObservable();
      let subscription;
      listenObservable();

      function listenObservable() {
        subscription = observable.subscribe(event => {
          switch (event.type) {
            case COMPANY_EVENTS.GET_COMPANY_SUCCESS:
              setCompanies(event.data.companies);
              break;
            case COMPANY_EVENTS.GET_COMPANY_FAILURE:
              GenericWindowPostMessage('FAILURE_TOAST', {
                toastMessage: event.data || TOAST_MESSAGES.COMPANY_EVENTS,
              });
              setCompanies([]);
              setLoader(false);
              break;

            case TEAM_EVENTS.GET_TEAM_SUCCESS:
              setTeamsOptions(event.data.teams);
              break;
            case DEPARTMENT_EVENTS.GET_DEPARTMENT_SUCCESS:
              setDepartmentOptions(event?.data?.departments);
              break;
            case EMPLOYEE_EVENTS.GET_EMPLOYEE_SUCCESS:
              setDefaultEmployees(event.data.employees);
              if (event?.data?.employees) {
                setEmployees(event?.data?.employees?.map(element => element?.user));
              }
              setEmployeeLoader(false);
              break;
            case PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_SUCCESS:
              let pageSize =
                cookies.get('pageSizeEmployeeSelectionTab') !== undefined
                  ? cookies.get('pageSizeEmployeeSelectionTab')
                  : 5;
              setPageEmployeeSelection(event?.data?.count === 0 ? 0 : Math.ceil(event.data.count / pageSize));
              setEmployeesList(event?.data?.results || []);
              setLoading(false);
              break;
            case PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_FAILURE:
              setEmployeesList([]);
              GenericWindowPostMessage(PTO_POLICY_EVENTS.FAILURE_TOAST, {
                toastMessage: event.data || TOAST_MESSAGES.GET_EMPLOYEE_LIST_FAILURE_FALLBACK,
              });
              setLoading(false);
              break;
            case PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_FAILURE:
              GenericWindowPostMessage('FAILURE_TOAST', {
                toastMessage: event?.data?.errorMessage || TOAST_MESSAGES.UPDATE_POLICY_BALANCE_FAILURE_FALLBACK,
              });

              setEmployeesList(oldData =>
                oldData.map((oldValue, index) => {
                  if (oldValue?.employee_id === event?.data?.prvData?.employee_id) {
                    return {
                      ...oldValue,
                      balance: event?.data?.prvData?.balance,
                    };
                  } else {
                    return oldValue;
                  }
                }),
              );
              setTimeout(() => setLoading(false), 100);
              break;
            case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS:
              if (event.data.key === 'add') {
                GenericWindowPostMessage('SUCCESS_TOAST', {
                  toastMessage: TOAST_MESSAGES.EMPLOYEE_ADD_SUCCESS,
                });
                getPolicyEmployeesList();
              } else if (event.data.key === 'remove') {
                GenericWindowPostMessage('SUCCESS_TOAST', {
                  toastMessage: TOAST_MESSAGES.EMPLOYEE_REMOVE_SUCCESS,
                });
                getPolicyEmployeesList();
              }

              break;
            case PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_SUCCESS:
              GenericWindowPostMessage(PTO_POLICY_EVENTS.SUCCESS_TOAST, {
                toastMessage: TOAST_MESSAGES.UPDATE_POLICY_BALANCE_SUCCESS,
              });
              setLoading(false)
              break;
            default:
          }
        });
      }
      return () => subscription?.unsubscribe();
    },
    [cookies, eventEmitter, getPolicyEmployeesList, setEmployeeLoader, setLoader, setLoading],
  );

  const savePage = () => {
    GenericWindowPostMessage('SAVE');
  };

  return {
    defaultEmployees,
    updateInputBalance,
    companies,
    setCurrentPage,
    setCurrentPageSize,
    savePage,
    onDepartmentSelect,
    onTeamSelect,
    onFetchData,
    pageSizeChange,
    pageChange,
    currentPage,
    pageEmployeeSelection,
    currentPageSize,
    teamsOptions,
    employees,
    departmentsOptions,
    employeesList,
  };
};

export default useEmployeeData;
